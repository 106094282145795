import { render, staticRenderFns } from "./CustomerCare.vue?vue&type=template&id=143bb2c1&scoped=true&"
import script from "./CustomerCare.vue?vue&type=script&lang=js&"
export * from "./CustomerCare.vue?vue&type=script&lang=js&"
import style0 from "./CustomerCare.vue?vue&type=style&index=0&id=143bb2c1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143bb2c1",
  null
  
)

export default component.exports